<template>
    <div class="types-work">
        <div class="types-work__type" v-for="info in list">
            <div class="types-work__point"></div>
            <div class="types-work__info">
                <h4>{{ info }}</h4>
            </div>
        </div>
    </div>
</template>
 
<script>
export default {
    name: 'TypesWork',
    props: {
        list: { type: Array }
    }
}
</script>
 
<style >
.types-work {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(auto-fit, 40px);
    row-gap: 20px;
    column-gap: 20px;
}

.types-work__type {
    background-color: white;
    border-radius: 5px;
    display: flex;
    padding: 14px;
    align-items: center;
    gap: 12px;
    max-height: 40px;
    width: fit-content;
    height: 12px;
}

.types-work__point {
    height: 14px;
    min-width: 14px;
    border-radius: 50%;
    background-color: #F2693D;
}

.types-work__info h4 {
    margin: 0;
    font-weight: 300;
    font-size: clamp(1rem, calc(5px + 1vw), 20px);
}

@media(max-width: 1280px) {
    .types-work {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(auto-fit, 40px);
    }
}

@media(max-width: 892px) {
    .types-work {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(auto-fit, 40px);
        justify-content: stretch;
    }
}

@media(max-width: 465px) {
    .types-work__type {
        height: 16px;
    }
}
</style>