<template>
    <div class="triad __container">
        <TriadUnit
            v-bind="{ src: 'remontView/guarantee.png', title: 'Гарантия качества', text: 'Гарантия на все виды ремонтных работ прописана в договоре' }" />
        <TriadUnit
            v-bind="{ src: 'remontView/worker.png', title: 'Работа специалистов', text: 'Работа квалифицированных специалистов ' }" />
        <TriadUnit
            v-bind="{ src: 'remontView/relationship.png', title: 'Отношения с клиентом', text: 'Доверие заказчика и взаимоотношения - вот наша цель' }" />
    </div>
</template>
 
<script>
import TriadUnit from "@/components/unit/TriadUnit.vue";

export default {
    name: 'Triad',
    components: { TriadUnit }
}
</script>
 
<style >
.triad {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
</style>