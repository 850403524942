import { createRouter, createWebHistory } from 'vue-router'
import RemontView from '@/views/RemontView.vue'
import AboutView from '@/views/AboutView.vue'
import ServicesView from '@/views/ServicesView.vue'
import PortfolioView from '@/views/PortfolioView.vue'
import FinishingWorkView from '@/views/FinishingWorkView.vue'
import ContactView from '@/views/ContactView.vue'
import NotFoundView from '@/views/NotFoundView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: { title: 'Ремонт жилых помещений | ООО «Все Решения»' },
    component: RemontView,
  },
  {
    path: '/about',
    name: 'about',
    meta: { title: 'О нас | ООО «Все Решения»' },
    component: AboutView
  },
  {
    path: '/services',
    name: 'services',
    meta: { title: 'Дополнительные услуги | ООО «Все Решения»' },
    component: ServicesView
  },
  {
    path: '/portfolio',
    name: 'portfolio',
    meta: { title: 'Портфолио | ООО «Все Решения»' },
    component: PortfolioView
  },
  {
    path: '/finishing',
    name: 'finishing',
    meta: { title: 'Отделочные работы | ООО «Все Решения»' },
    component: FinishingWorkView
  },
  {
    path: '/contacts',
    name: 'contacts',
    meta: { title: 'Контакты | ООО «Все Решения»' },
    component: ContactView
  },
  {
    path: '/404',
    name: '404',
    meta: { title: '404 | ООО «Все Решения»' },
    component: NotFoundView
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/404'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' };
    } else {
      setTimeout(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      }, 50);
    }
  },
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});

export default router
