<template>
  <div class="remont">
    <Chapter style="margin-top: 0;" v-bind="{ text: ['Ремонт', 'помещений'], src: 'remontView/Remont.webp' }" />
    <Triad />
    <section class="__container">
      <div class="__section types-remont">
        <div class="__section__title">
          <h1>Виды производимых ремонтных работ</h1>
        </div>
        <div class="types-remont__types">
          <div class="types-remont__type type-1">
            <h3>Косметческий</h3>
            <SpecificationUnit v-bind="{ specifications: cosmetic }" />
          </div>
          <div class="types-remont__type type-2">
            <h3>Эконом</h3>
            <SpecificationUnit v-bind="{ specifications: econom }" />
          </div>
          <div class="types-remont__type type-3">
            <h3>Евро</h3>
            <SpecificationUnit v-bind="{ specifications: euro }" />
          </div>
          <div class="types-remont__type type-4">
            <h3>Капитальный</h3>
            <SpecificationUnit v-bind="{ specifications: capit }" />
          </div>
        </div>
      </div>
    </section>
    <section class="__container">
      <div class="__section steps-remont">
        <div class="__section__title">
          <h1>Этапы ремонтых работ</h1>
        </div>
        <div class="steps">
          <div class="steps-block steps-block__1" style="background-color: #68A4FF;">
            <div class="steps-block__count">
              <p style="color: white;">1</p>
            </div>
            <div class="steps-block__descript">
              <div class="steps-block__title">
                <h4 style="color: white;">Оформление заказа</h4>
              </div>
              <div class="steps-block__info">
                <p style="color: white;">Свяжитесь с нами по телефону <a style="color: white;"
                    href="tel:+7 (923) 344-29-43">+7 (923)
                    344-29-43</a> или напишите в
                  <a style="color: white;" href="">What's App</a>.
                </p>
              </div>
            </div>
          </div>
          <div class="steps-block steps-block__2">
            <div class="steps-block__count">
              <p>2</p>
            </div>
            <div class="steps-block__descript">
              <div class="steps-block__title">
                <h4>Осмотр квартиры</h4>
              </div>
              <div class="steps-block__info">
                <p>
                  К вам выезжает замерщик, который соберет подробную информацию о состоянии вашей квартиры, определит
                  объем подготовительных работ и проконсультирует вас.
                </p>
              </div>
            </div>
          </div>
          <div class="steps-block steps-block__3">
            <div class="steps-block__count">
              <p>3</p>
            </div>
            <div class="steps-block__descript">
              <div class="steps-block__title">
                <h4>Дизайн проект</h4>
              </div>
              <div class="steps-block__info">
                <p>
                  В случае, если вы хотите иметь полное представление о результате ремонта, на встрече с дизайнером вы
                  сможете обсудить все тонкости будущего интерьера.
                </p>
              </div>
            </div>
          </div>
          <div class="steps-block steps-block__4">
            <div class="steps-block__count">
              <p>4</p>
            </div>
            <div class="steps-block__descript">
              <div class="steps-block__title">
                <h4>Смета на ремонт</h4>
              </div>
              <div class="steps-block__info">
                <p>Специалист составит подробную смету на ремонт квартиры.
                </p>
              </div>
            </div>
          </div>
          <div class="steps-block steps-block__5">
            <div class="steps-block__count">
              <p>5</p>
            </div>
            <div class="steps-block__descript">
              <div class="steps-block__title">
                <h4>Подписание договора</h4>
              </div>
              <div class="steps-block__info">
                <p>
                  Стоимость и сроки проведения ремонта квартиры будут закреплены в договоре.
                </p>
              </div>
            </div>
          </div>
          <div class="steps-block steps-block__6">
            <div class="steps-block__count">
              <p>6</p>
            </div>
            <div class="steps-block__descript">
              <div class="steps-block__title">
                <h4>Закупка материалов</h4>
              </div>
              <div class="steps-block__info">
                <p>
                  Все строительные материалы, указанные в смете будут закуплены по отличным ценам и вовремя доставлены
                  на
                  объект.
                </p>
              </div>
            </div>
          </div>
          <div class="steps-block steps-block__7">
            <div class="steps-block__count">
              <p>7</p>
            </div>
            <div class="steps-block__descript">
              <div class="steps-block__title">
                <h4>Работы по ремонту</h4>
              </div>
              <div class="steps-block__info">
                <p>Работы с соблюдением технологий, ГОСТ и СНиП.
                </p>
              </div>
            </div>
          </div>
          <div class="steps-block steps-block__8">
            <div class="steps-block__count">
              <p>8</p>
            </div>
            <div class="steps-block__descript">
              <div class="steps-block__title">
                <h4>Уборка</h4>
              </div>
              <div class="steps-block__info">
                <p>
                  После завершения работ, проводится тщательный клининг.
                </p>
              </div>
            </div>
          </div>
          <div class="steps-block steps-block__9" style="background-color: #F2693D;">
            <div class="steps-block__count">
              <p style="color: white;">9</p>
            </div>
            <div class="steps-block__descript">
              <div class="steps-block__title">
                <h4 style="color: white;">Сдача объекта</h4>
              </div>
              <div class="steps-block__info">
                <p style="color: white;">
                  После осмотра, вы принимаете помещение и подписываете акт приемки.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Chapter v-bind="{ text: ['Электро', 'монтаж'], src: 'remontView/El-montage.webp' }" />
    <section class="__container">
      <div class="__section el-montage">
        <Description v-bind="{
      text: [`Компания Все решения предлагает профессиональное восстановление электропроводки
          и установку защитного оборудования. Наша специализированная команда обладает обширным опытом и современным
          инструментом для решения сложных задач. Мы гарантируем полное выполнение заказа под ключ и ответственное
          отношение к каждому клиенту.`, `Для получения помощи достаточно позвонить нам и описать проблему. Заказывайте профессиональные услуги у нас, чтобы быть уверенными в качестве работы.`]
    }" />
        <div class="__section__title">
          <h1>Виды услуг</h1>
        </div>
        <div class="el-montage__list">
          <TypesWork
            v-bind="{ list: ['Прокладка кабеля в гофре', 'Монтаж терморегулятора теплого пола', 'Установка настенного светильника (бра)', 'Монтаж счетчика электропотребления', 'Установка монтажной коробки', 'Установка люстры', 'Монтаж вентилятора', 'Сборка, монтаж распаечной коробки', 'Установка розетки/выключателя', 'Установка потолочного светильника', 'Монтаж однополюсного автомата', 'Монтаж светодиодной ленты'] }" />
        </div>
      </div>
    </section>
    <Chapter v-bind="{ text: ['Сантехника'], src: 'remontView/Plumbing.webp' }" />
    <section class="__container">
      <div class="__section plumbing">
        <Description v-bind="{
      text: [`Установка сантехники должна быть выполнена профессионалами с опытом работы и специализированным оборудованием. Неправильная установка может привести к серьезным проблемам и поломкам. В нашей компании в Красноярске мы предлагаем качественные сантехнические работы - ремонт, перепланировку и замену приборов. Мы гарантируем высокое качество работы и долговечность.`]
    }" />
        <div class="__section__title">
          <h1>Виды услуг</h1>
        </div>
        <TypesWork
          v-bind="{ list: ['Водопровод горячей и холодной воды', 'Подключение электрических приборов', 'Установка сантехники', 'Монтаж радиаторов отопления', 'Установка ванны, душ кабины, унитаза', 'Устройство канализаций'] }" />
      </div>
    </section>
    <Chapter v-bind="{ text: ['Демонтаж'], src: 'remontView/Dismantling.webp' }" />
    <section class="__container">
      <div class="__section dismantling">
        <Description v-bind="{
      text: [`Работы по демонтажу в квартире могут отличаться по уровню сложности. К наиболее лёгким работам относится демонтаж: деревянных полов и стяжек, подвесных потолков, окон, дверей и сантехники, покрытия стен (обои, штукатурка и прочее).`, `Следующее по сложности и трудозатратности – это демонтаж простенков, водопроводной арматуры, канализационных труб и электрической сети квартиры.`,
        'К работам повышенной сложности относится демонтаж несущих стен. Для его проведения необходимо проектно-исследовательское заключение и разрешение архитектурного надзора, выданное на его основании.']
    }" />
        <div class="__section__title">
          <h1>Этапы демонтажных работ</h1>
        </div>
        <div class="dismantling__steps">
          <div class="dismantling__step">
            <div class="dismantling__count">
              <p>1</p>
            </div>
            <div class="dismantling__info">
              <p>Утверждение технического задания заказа</p>
            </div>
          </div>
          <div class="dismantling__step">
            <div class="dismantling__count">
              <p>2</p>
            </div>
            <div class="dismantling__info">
              <p>Изучение места работы, составление сметы и плана проведения работ</p>
            </div>
          </div>
          <div class="dismantling__step">
            <div class="dismantling__count">
              <p>3</p>
            </div>
            <div class="dismantling__info">
              <p>Получение соответствующих разрешений</p>
            </div>
          </div>
          <div class="dismantling__step">
            <div class="dismantling__count">
              <p>4</p>
            </div>
            <div class="dismantling__info">
              <p>Исполнение заказа по демонтажу</p>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import Chapter from "@/components/Chapter.vue";
import Description from "@/components/Description.vue";
import Triad from "@/components/Triad.vue";
import TypesWork from "@/components/TypesWork.vue";
import SpecificationUnit from "@/components/unit/SpecificationUnit.vue";


export default {
  name: 'RemontView',

  components: { Chapter, Triad, SpecificationUnit, Description, TypesWork },
  computed: {
    cosmetic() {
      const arr = [];
      arr.push({ title: "Потолок", info: "грунтование и шпатлевание, окраска в/д краской или натяжные потолки" }, { title: "Стены", info: "демонтаж и поклейка обоев, шпатлевание стен" }, { title: "Пол", info: "замена покрытия, монтаж плинтусов и порожков" }, { title: "Электрика", info: "замена розеток и выключателей" }, { title: "Сантехника", info: "стандартная разводка труб, установка санфаянса" }, { title: "Санузел", info: "–" })
      return arr
    },
    econom() {
      const arr = [];
      arr.push({ title: "Потолок", info: "укладка линолеума или ламината" }, { title: "Стены", info: "выравнивание стен под правило, оклейка обоев" }, { title: "Пол", info: "визуальное выравнивание, окраска в/д краской или натяжные потолки" }, { title: "Электрика", info: "демонтаж/монтаж розеток и выключателей, частичная замена проводки" }, { title: "Сантехника", info: "установка санфаянса и радиаторов" }, { title: "Санузел", info: "укладка плитки стандартного размера" })
      return arr
    },
    euro() {
      const arr = [];
      arr.push({ title: "Потолок", info: "+ демонтаж старого покрытия, идеальное выравнивание" }, { title: "Стены", info: "+ демонтаж перегородок, устройство новых проемов, возведение перегородок из гипсокартона, поклейка обоев" }, { title: "Пол", info: "+ стяжка, паркетная доска" }, { title: "Электрика", info: "полная замена, сборка электрощита" }, { title: "Сантехника", info: "разводка труб и монтаж сантехнических приборов" }, { title: "Санузел", info: "укладка плитки любого размера, изготовление душевой кабины" })
      return arr
    },
    capit() {
      const arr = [];
      arr.push({ title: "Потолок", info: "+ финишное выравнивание пола, кварцвинил" }, { title: "Стены", info: "+ перепланировка, штукатурка по маякам, флизелиновые обои, обои под покраску, покраска обоев" }, { title: "Пол", info: "+ реечные или гипрочные потолки" }, { title: "Электрика", info: "полная замена, сборка электрощита" }, { title: "Сантехника", info: "разводка труб и монтаж санфаянса" }, { title: "Санузел", info: "+ теплый пол" })
      return arr
    }
  },

}
</script>

<style scoped>
.steps {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 200px);
  row-gap: 20px;
  column-gap: 25px;
}

.steps-block {
  background-color: white;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 25px;
}

.steps-block__1 {
  grid-column-start: 1;
  grid-column-end: 2;
}

.steps-block__2 {
  grid-column-start: 2;
  grid-column-end: 4;
}

.steps-block__3 {
  grid-column-start: 1;
  grid-column-end: 3;
}

.steps-block__4 {
  grid-column-start: 3;
  grid-column-end: 4;
}

.steps-block__5 {
  grid-column-start: 1;
  grid-column-end: 2;
}

.steps-block__6 {
  grid-column-start: 2;
  grid-column-end: 4;
}

.steps-block__7 {
  grid-column-start: 1;
  grid-column-end: 2;
}

.steps-block__8 {
  grid-column-start: 2;
  grid-column-end: 3;
}

.steps-block__9 {
  grid-column-start: 3;
  grid-column-end: 4;
}

.steps-block__count p {
  font-family: Chivo, sans-serif;
  font-weight: 400;
  font-size: clamp(3rem, calc(30px + 5vw), 128px);
  color: #F2693D;
  padding: 0;
  margin: 0;
}

.steps-block__descript {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: 20px;
}

.steps-block__title {
  margin-bottom: 20px;
}

.steps-block__title h4 {
  font-weight: 500;
  font-size: clamp(1.4rem, calc(8px + 2vw), 26px);
  margin: 0;
  padding: 0;
}

.steps-block__info p,
.steps-block__info a {
  color: #000;
  font-size: clamp(0.8rem, calc(8px + 1vw), 20px);
  font-weight: 300;
}

.steps-block__info a {
  white-space: nowrap;
}

.types-remont__types {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  column-gap: 25px;
  row-gap: 35px;
}

.types-remont__type {
  background-color: white;
  border-radius: 10px;
  padding: 25px;
}

.types-remont__type h3 {
  margin: 0 0 20px 0;
  padding: 0;
  font-weight: 400;
  font-size: clamp(1.4rem, calc(14px + 3vw), 36px);
}

.dismantling__steps {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  gap: 20px;
}

.dismantling__step {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  padding: 15px 25px;
}

.dismantling__count p {
  font-family: Chivo, sans-serif;
  color: #68A4FF;
  font-size: clamp(1.3rem, calc(16px + 2vw), 48px);
}

.dismantling__info {
  margin-left: 20px;
}

.dismantling__info p {
  font-weight: 300;
  font-size: clamp(1rem, calc(10px + 1vw), 20px);
}

@media(max-width: 1400px) {
  .dismantling__steps {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
}

@media(max-width: 1280px) {
  .steps {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 200px);
  }

  .steps-block__1 {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .steps-block__2 {
    grid-column-start: 2;
    grid-column-end: 3;
  }

  .steps-block__3 {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .steps-block__4 {
    grid-column-start: 2;
    grid-column-end: 3;
  }

  .steps-block__5 {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .steps-block__6 {
    grid-column-start: 2;
    grid-column-end: 3;
  }

  .steps-block__7 {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .steps-block__8 {
    grid-column-start: 2;
    grid-column-end: 3;
  }

  .steps-block__9 {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .types-remont__types {
    grid-template-columns: 1fr;
    grid-template-rows: none;
  }
}


@media(max-width: 768px) {
  .steps {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(9, 150px);
  }

  .steps-block__title {
    margin-bottom: 10px;
  }

  .steps-block__1 {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .steps-block__2 {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .steps-block__3 {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .steps-block__4 {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .steps-block__5 {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .steps-block__6 {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .steps-block__7 {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .steps-block__8 {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .steps-block__9 {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .dismantling__steps {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
  }
}

@media(max-width: 425px) {
  .steps {
    grid-template-rows: repeat(9, 150px);
  }
}

@media(max-width: 320px) {
  .steps {
    grid-template-rows: repeat(9, 175px);
  }
}
</style>
