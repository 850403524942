<template>
    <div class="__container">
        <div class="__section notfound">
            <h1>404</h1>
            <p>Страница не найдена.<br>Но есть другие интересные страницы</p>
        </div>
    </div>
</template>

<script>


export default {
    name: 'NotFoundView'
}
</script>

<style scoped>
.notfound {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.notfound h1 {
    margin: 0;
    font-family: Chivo, sans-serif;
    font-weight: 600;
    font-size: clamp(3rem, 12vw, 164px);
    color: #F2693D
}

.notfound p {
    margin: 0;
    text-align: center;
    font-size: clamp(1rem, 4vw, 28px);
}
</style>