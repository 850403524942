<script>
import Chapter from "@/components/Chapter.vue"
import SpecificationUnit from "@/components/unit/SpecificationUnit.vue"

export default {
    name: "ContactView",
    components: { Chapter, SpecificationUnit },
    computed: {
        contact() {
            const con = [];
            con.push({ title: "Телефон", info: "+7 (993) 464-29-43" }, { title: "Почта", info: "vse_resheniya@mail.ru" }, { title: "Время работы", info: "Пн-Пт с 9:00 до 18:00" }, { title: "Адрес", info: "Красноярск, ш. Северное, д. 17, офис 403" })
            return con
        }
    }
}
</script>

<template>
    <div class="contact">
        <Chapter style="margin-top: 0;" v-bind="{ text: ['Контакты'], src: 'contactView/Contact.webp' }" />
        <div class="__container">
            <div class="contact__information">
                <div class="contact__specification">
                    <SpecificationUnit v-bind="{ specifications: contact }" />
                </div>
                <div class="contact__map">
                    <div style="position:relative;overflow:hidden;"><a
                            href="https://yandex.ru/maps/62/krasnoyarsk/?utm_medium=mapframe&utm_source=maps"
                            style="color:#eee;font-size:12px;position:absolute;top:0px;">Красноярск</a><a
                            href="https://yandex.ru/maps/62/krasnoyarsk/house/severnoye_shosse_17s2/bUsYfwVnSkABQFtsfXx2dXthZA==/?ll=92.927905%2C56.074891&utm_medium=mapframe&utm_source=maps&z=17.14"
                            style="color:#eee;font-size:12px;position:absolute;top:14px;">Северное шоссе, 17с2 —
                            Яндекс Карты</a><iframe class="map"
                            src="https://yandex.ru/map-widget/v1/?ll=92.927905%2C56.074891&mode=whatshere&whatshere%5Bpoint%5D=92.925582%2C56.073980&whatshere%5Bzoom%5D=17&z=17.14"
                            frameborder="1" allowfullscreen="true"
                            style="position:relative; border-radius: 10px; border: none;"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>



<style scoped>
.contact__information {
    background-color: white;
    border-radius: 10px;
    margin: 0 15px;
    padding: 25px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr;
    gap: 30px
}

.map {
    width: 456px;
    height: 380px
}

@media(max-width:1023px) {
    .map {
        width: 416px;
        height: 320px;
    }
}

@media(max-width: 950px) {
    .map {
        width: 100%;
        height: 320px;
    }

    .contact__information {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
    }
}

@media(max-width: 520px) {
    .map {
        width: 100%;
        height: 300px;
    }

    .contact__information {
        gap: 20px
    }
}
</style>