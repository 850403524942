<template>
    <div style="background-color: white;">
        <header class="header __container">
            <div class="header__logo logo">
                <router-link to="/"><img alt="Logo" src="@/assets/logo.svg" max-height="80" loading="lazy"></router-link>
            </div>
            <div class="header__bar">
                <div class="header__info">
                    <!-- <div class="header__geo header-info">
                     <img src="@/assets/icons/header/geo-alt-svgrepo-com 1.svg">Красноярск, шоссе Северное, д. 17, помещ. 8,
                     ком. 17
                 </div>
                 <div class="header__question header-info">
                     У вас вопрос? Звоните или пишите нам
                 </div> -->
                </div>
                <nav class="header__links" @click="closeHamburger">
                    <router-link to="/" class="header__link">Ремонт</router-link>
                    <router-link to="/finishing" class="header__link">Отделка</router-link>
                    <router-link to="/portfolio" class="header__link">Портфолио</router-link>
                    <router-link to="/services" class="header__link">Доп услуги</router-link>
                    <router-link to="/about" class="header__link">О нас</router-link>
                    <router-link to="/contacts" class="header__link">Контакты</router-link>
                </nav>
            </div>
            <div class="header__contact">
                <div class="header__tel">
                    +7 (993) 464-29-43
                </div>
                <div class="header__jobtime">
                    Пн-Пт (с 9:00 до 18:00)
                </div>
            </div>
            <div class="header__hamburger hamburger" @click="hamburger">
                <span></span>
            </div>
        </header>
    </div>
</template>
 
<script >
export default {
    name: 'Navbar',
    methods: {
        hamburger(event) {
            const links = document.querySelector('.header__links')
            const body = document.querySelector('body')

            event.currentTarget.classList.toggle('hamburger_active')
            links.classList.toggle('header__links_active')
            body.classList.toggle('_lock')
        },
        closeHamburger(event) {
            if (document.documentElement.clientWidth < 720) {
                if (event.target.closest('.header__link')) {
                    const links = document.querySelector('.header__links')
                    const body = document.querySelector('body')
                    const hamburger = document.querySelector('.hamburger')

                    hamburger.classList.remove('hamburger_active')
                    links.classList.remove('header__links_active')
                    body.classList.remove('_lock')
                }
            }
        }
    }
}
</script>
 
<style scoped>
.header {
    /* background: #FFF; */
    width: 100%;
    max-height: 104px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: clamp(1rem, calc(8px + 0.8vw), 20px);
    padding: 15px 0;
}

.header__logo {
    max-height: 80px;
    margin-left: 15px;
}

.header__bar {
    flex: 1 1 auto;
    padding: 0 25px;
    display: flex;
    flex-direction: column;
    height: 81px;
    justify-content: center;
}

.header__info {
    display: flex;
    justify-content: space-between;
}

.header-info {
    max-height: 14px;
    color: #FFF;
    padding: 9px 12px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px
}

.header__geo {
    background: #68A4FF;
}

.header__question {
    background: #F2693D;
}

.header__links {
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    letter-spacing: 0em;
    text-transform: uppercase;
}

.header__link:not(:last-child) {
    margin-right: 25px;
}

.header a {
    color: #000;
    text-decoration: none;
}

.header__hamburger {
    display: none;
    margin-right: 15px;
}

.header__contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 15px;
}

.header__tel {
    font-size: clamp(1.2rem, calc(10px + 0.8vw), 24px);
}

.header__jobtime {
    color: #7D7D7D;
    text-align: center;
    font-size: clamp(0.8rem, calc(6px + 0.8vw), 14px);
}

nav a.router-link-exact-active {
    color: #68A4FF;
}

@media (max-width: 1330px) {
    .header__contact {
        display: none;
    }
}

@media (max-width: 954px) {
    .header__info {
        display: none;
    }

    .header__bar {
        justify-content: center;
    }

}

@media (max-width: 719px) {
    .header__links {
        position: fixed;
        flex-direction: column;
        align-items: flex-start;
        justify-content: start;
        top: 0;
        left: -100%;
        transform: translate(-100%, 0px);
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 1);
        transition: left 0.4s ease 0s;
        overflow: auto;
        z-index: 5;
    }

    .header__links_active {
        left: 100%;
    }

    .header__link {
        margin: 10px 15px;
        font-size: 24px;
    }

    .header__link:first-child {
        margin-top: 25px;
    }

    .header__bar {
        padding: 0;
        align-items: end;
    }

    .header__hamburger {
        display: block;
    }

    .hamburger {
        z-index: 5;
        cursor: pointer;
        width: 30px;
        height: 18px;
        position: relative;
    }

    .hamburger span,
    .hamburger::before,
    .hamburger::after {
        left: 0;
        position: absolute;
        height: 10%;
        background-color: #000;
        width: 100%;
        transition: all 0.3s ease 0s;
    }

    .hamburger::after,
    .hamburger::before {
        content: "";
    }

    .hamburger::before {
        top: 0;
    }

    .hamburger::after {
        bottom: 0;
    }

    .hamburger span {
        top: 50%;
        transform: scale(1) translate(0px -50%);
    }

    .hamburger_active span {
        transform: scale(0)
    }

    .hamburger_active::before {
        top: 50%;
        transform: rotate(-45deg);
    }

    .hamburger_active::after {
        top: 50%;
        transform: rotate(45deg);
    }

}
</style>