<template>
    <div class="description">
        <div class="description__text">
            <p v-for="info in text">
                {{ info }}
            </p>
            <div class="description__list" v-if="list">
                <h5>{{ list.title }}</h5>
                <ol>
                    <li v-for="ls in list.steps">{{ ls }}</li>
                </ol>
            </div>

        </div>
    </div>
</template>
 
<script>
export default {
    name: "Description",
    props: {
        text: { type: Array },
        list: { type: Object, default: undefined }
    },
}
</script>
 
<style >
.description {
    background-color: white;
    border-radius: 10px;
    padding: 25px;
    margin-bottom: 60px;
}

.description__text {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.description__text p,
.description__list li {
    font-weight: 300;
    font-size: clamp(0.9rem, calc(8px + 2vw), 20px)
}

.description__list h5 {
    margin: 0;
    padding: 0;
    font-weight: 500;
    font-size: clamp(0.9rem, calc(8px + 2vw), 20px)
}

.description__list ol {
    padding-left: 25px;
}

.description__list li {
    font-size: clamp(0.9rem, calc(8px + 2vw), 20px);
    padding-left: 12px;
}

@media (max-width: 1440px) {
    .description {
        margin-bottom: 40px;
    }
}
</style>