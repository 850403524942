<template>
    <div style="background-color: white;">
        <footer class="footer __container">
            <div class="footer__info help-inf">
                <img src="@/assets/logo.svg" alt="logo" max-width="132px">
                <div>
                    <p>
                        ООО «Все решения»
                    </p>
                    <p>
                        ИНН: 2466296692
                    </p>
                    <p>
                        КПП: 246601001
                    </p>
                    <p>
                        ОГРН: 1232400023624
                    </p>
                </div>
            </div>
            <div class="footer__contact help-inf">
                <h3 class="help-inf__title">Контакты</h3>
                <div class="help-inf__info">
                    <div class="help-inf__inf">
                        <div>
                            <img src="@/assets/icons/footer/tel.svg" alt="tel">
                        </div>
                        <div>+7 (993) 464-29-43</div>
                    </div>
                    <div class="help-inf__inf">
                        <img src="@/assets/icons/footer/email.svg" alt="email">
                        <div>vse_resheniya@mail.ru</div>
                    </div>
                    <div class="help-inf__inf">
                        <div>
                            <img src="@/assets/icons/footer/time.svg" alt="time">
                        </div>
                        <div>
                            Пн-Пт с 9:00 до 18:00
                        </div>
                    </div>
                    <div class="help-inf__inf">
                        <div><img src="@/assets/icons/footer/geo.svg" alt="geo"></div>
                        <div>Красноярск, ш. Северное,<br>д. 17, офис 403</div>
                    </div>
                    <div class="help-inf__society">
                        <a href="https://wa.me/79934642943"><img src="@/assets/icons/society/WhatsApp.svg"
                                alt="What's App"></a>
                    </div>
                </div>
            </div>
            <div class="footer__info help-inf">
                <h3 class="help-inf__title">Информация</h3>
                <div class="help-inf__links">
                    <div class="help-inf__link">
                        <router-link to="/about">О нас</router-link>
                    </div>
                    <div class="help-inf__link">
                        <router-link to="/contacts">Контакты</router-link>
                    </div>
                </div>
            </div>
            <div class="footer__pay help-inf">
                <h3 class="help-inf__title">Способы оплаты</h3>
                <div class="help-inf__pay">
                    <img class="help-inf__img" src="@/assets/icons/footer/mir.svg" alt="МИР">
                    <img class="help-inf__img" src="@/assets/icons/footer/visa.svg" alt="VISA">
                    <img class="help-inf__img" src="@/assets/icons/footer/mastercard.svg" alt="Mastercard">

                </div>
                <p>Безналичный расчёт</p>
            </div>
        </footer>
    </div>
</template>
 
<script>
export default {
    name: "Footer"
}
</script>
 
<style >
.footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    font-size: clamp(1rem, calc(8px + 1vw), 20px);
}

.footer p,
h3 {
    padding: 0;
    margin: 0;
}

.footer p {
    margin-top: 22px;
}

.footer__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.footer__info img {
    max-width: 132px;
}

.footer__contact {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.help-inf {
    margin: 15px 25px;
    flex: 1 0 auto;
}

.help-inf__title {
    font-weight: 700;
    text-transform: uppercase;
    font-size: clamp(1.2rem, calc(10px + 1vw), 22px);
    text-align: left;
    margin-bottom: 25px;
}

.help-inf__links {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.help-inf__link a {
    text-decoration: none;
    color: black;
    font-size: clamp(1rem, calc(8px + 1vw), 20px);
}

.help-inf__link a:hover {
    text-decoration: underline;
}

.help-inf__info {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.help-inf__inf {
    display: flex;
    align-items: center;
    gap: 12px;
}

.help-inf__inf img {
    width: 20px;
    height: 20px;
}

.help-inf__society {
    display: flex;
    align-items: center;
}

.help-inf__pay {
    display: flex;
    align-items: center;
    /* flex-wrap: wrap; */
    max-width: 71px;
}

.help-inf__pay img {
    width: 100%;
    height: auto;
}

.help-inf__img:not(:last-child) {
    margin-right: 15px;
}
</style>