<template>
    <div class="specification" v-for="specification in specifications">
        <div class="specification__title">
            <h4>{{ specification.title }}</h4>
        </div>
        <div class="specification__info">
            <div class="specification__column"></div>
            <div v-if="typeof (specification.info) == 'string'">
                <p>{{ specification.info }}</p>
            </div>
            <div v-else>
                <p v-for="data in specification.info">{{ data }}</p>
            </div>
        </div>
    </div>
</template>
 
<script>
export default {
    name: 'SpecificationUnit',
    props: {
        specifications: { type: Array }
    }
}
</script>
 
<style >
.specification {
    display: flex;
}

.specification:not(:last-child) {
    margin-bottom: 20px;
}

.specification h4,
p {
    margin: 0;
    padding: 0;
}

.specification__title {
    display: flex;
    align-items: center;
    min-width: 165px;
}

.specification__title h4 {
    color: rgb(36, 130, 185);
    font-weight: 500;
    font-size: clamp(1.2rem, calc(8px + 2vw), 24px);
}

.specification__column {
    min-height: 64px;
    height: 100%;
    min-width: 2px;
    width: 2px;
    background-color: black;
    margin-right: 30px;
}

.specification__info {
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.specification__info p {
    font-weight: 300;
    font-size: clamp(1rem, calc(10px + 1vw), 20px);
    hyphens: auto;
}

@media(max-width: 768px) {
    .specification {
        flex-direction: column;
    }

    .specification__info {
        margin: 10px 0 0 10px;
    }

    .specification__column {
        min-height: 45px;
    }

    .specification__title {
        min-width: 130px;
    }
}

@media(max-width: 540px) {
    .specification__column {
        min-height: 40px;
    }

    .specification__title {
        min-width: 110px;
    }
}

@media(max-width: 425px) {
    .specification__column {
        min-height: 35px;
    }

    .specification__title {
        min-width: 100px;
    }
}

@media(max-width: 374px) {
    .specification__column {
        min-height: 30px;
    }

    .specification__title {
        min-width: 85px;
    }
}

@media(max-width: 325px) {
    .specification__column {
        min-height: 25px;
    }

    .specification__title {
        min-width: 75px;
    }
}
</style>