<template>
    <div class="services">
        <Chapter style="margin-top: 0;" v-bind="{ text: ['Услуги'], src: 'servicesView/Services.webp' }" />

        <section class="__container">
            <div class="desc__services">
                <Description
                    v-bind="{ text: [`Строительная компания «Все решения» не только предоставляет высококачественные ремонтные услуги, но также предлагает дополнительные сервисы, чтобы удовлетворить все потребности наших клиентов.`] }" />
            </div>

        </section>
        <section class="__container">
            <div class="__section garbage">
                <div class="__section__title">
                    <h1>Вывоз мусора</h1>
                </div>
                <Description v-bind="{
            text: [`Обращаясь в компанию Все решения, вы можете заказать услугу по вывозу мусора. Наша команда обеспечит вам быстрое и безопасное удаление строительного мусора, остатков от демонтажа или зачистки помещений.`, `У нас есть специализированные грузовики, которые позволяют нам эффективно выполнять данную задачу. Помимо этого, наши квалифицированные специалисты грамотно организуют процесс сбора и упаковки мусора, обеспечивая его правильную утилизацию. Таким образом, вы можете избежать нарушений законодательства и возможных штрафов.`],
            list: list
        }" />
            </div>
        </section>
        <section class="__container">
            <div class="__section material">
                <div class="__section__title">
                    <h1>Закупка материалов</h1>
                </div>
                <Description v-bind="{
            text: [`Наши специалисты имеют богатый опыт работы в закупках строительных материалов, что позволяет им эффективно выбирать надежных поставщиков с лучшими ценами и качеством товара. Мы учитываем все ваши требования и предпочтения, чтобы найти оптимальные варианты материалов для вашего проекта.
`, `Благодаря нашим долгосрочным партнерским отношениям с поставщиками, мы имеем возможность получать специальные цены, скидки и предложения, которые мы с удовольствием передаем нашим клиентам. Таким образом, вы можете сэкономить значительную сумму денег при заказе строительных материалов через нашу компанию.`,
                `Помимо экономии денежных средств, мы также обеспечиваем удобство и время, освобожденное от необходимости самостоятельно заниматься закупкой материалов. Наши специалисты выполнят все необходимые шаги, начиная от составления спецификации и поиска поставщиков, до оформления заказа и доставки материалов на объект.`,
                `Таким образом, закупка строительных материалов специалистами нашей компании - это возможность сэкономить деньги, время и избежать лишних хлопот. Вы можете полностью довериться нам и быть уверенными в получении качественных материалов по лучшей цене.`]
        }" />
            </div>
        </section>
        <section class="__container">
            <div class="__section hurry-up-repairs">
                <div class="__section__title">
                    <h1>Срочный ремонт</h1>
                </div>
                <Description style="margin-bottom: 0" v-bind="{
            text: [`Наши специалисты имеют большой опыт в проведении срочных ремонтных работ и обладают необходимыми знаниями и навыками, чтобы выполнить работы быстро и качественно. Мы используем только высококачественные материалы и современное оборудование, чтобы обеспечить долговечность и надежность ремонта.`, `Кроме того, мы предлагаем гибкое график выполнения работ, чтобы удовлетворить ваши индивидуальные потребности. Наша команда будет работать быстро и эффективно, чтобы минимизировать время, которое вам придется провести вне ваших помещений.`,
                `Мы также предлагаем конкурентоспособные цены за наши услуги и гарантируем прозрачность в расчетах. Вы будете знать все затраты заранее и не столкнетесь с неприятными сюрпризами в конечном счете.`,
                `
Если вам нужен срочный ремонт квартиры или офисного помещения, свяжитесь с нами прямо сейчас. Мы готовы приступить к работе как можно скорее, чтобы удовлетворить ваши потребности и ожидания.`]
        }" />
            </div>
        </section>
    </div>
</template>

<script>
import Chapter from "@/components/Chapter.vue"
import Description from "@/components/Description.vue"

export default {
    name: 'ServicesView',
    data() {
        return {
            list: {
                title: "Этапы вывоза мусора",
                steps: ['Вызов - Вы вызываете мастеров на определенный день с примерным обозначением объема мусора.', 'Выезд - Бригада грузчиков приезжает со всеми необходимыми мешками и приспособлениями для сбора и погрузки мусора.', 'Упаковка - Сортировка и упаковка мусора на 30% ускоряет процесс и экономит место при хранении и вывозе. К тому же, упаковка исключает повреждения лифта или подъезда осколками, или острыми углами.', 'Погрузка - Аккуратно выносим упакованный строительный мусор и компактно размещаем его в кузове транспорта.', 'Уборка - После погрузки убираем за собой мелкий остаточный мусор.', 'Утилизация - Вывозим и утилизируем мусор в специально отведенные для этого места']
            }
        }
    },
    components: { Chapter, Description }
}
</script>

<style scoped>
.desc__services {
    margin: 0 15px
}
</style>