<template>

  <Navbar />
  <div class="main">
    <router-view />
    <Question v-show="!notFoundPage" />
  </div>

  <Footer />
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import Question from "@/components/Question.vue"
import { useRoute } from 'vue-router'

export default {
  data() {
    return {
      currentPage: useRoute(),
      Page404: false
    }
  },
  components: { Navbar, Footer, Question },
  computed: {
    notFoundPage() {
      if (this.currentPage.name != '404') {
        this.Page404 = false
      } else {
        this.Page404 = true
      }
      return this.Page404
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Chivo&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300&display=swap');

#app {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  color: #000;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

body,
html {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}

body._lock {
  overflow: hidden;
}

.main {
  flex: 1 1 auto;
  width: 100%;
  background-color: #EFF3F8;
  padding-bottom: 80px;
}

.__container {
  max-width: 1410px;
  min-width: 290px;
  margin: 0 auto 0 auto;
}

.__section {
  margin: 60px 15px 0;
}

.__section__title h1 {
  font-weight: 500;
  font-size: clamp(1.8rem, calc(10px + 3vw), 48px);
  margin: 0 0 40px 0;
  padding: 0;
}

@media (max-width: 1440px) {
  .__section {
    margin-top: 40px;
  }

  .__section__title h1 {
    margin: 0 0 20px 0;
  }
}

@media(max-width:425px) {
  .main {
    padding-bottom: 60px;
  }
}
</style>
