<template>
  <div class="about">
    <Chapter style="margin-top: 0;" v-bind="{ text: ['О нас'], src: 'aboutView/About.webp' }" />
    <Triad />
    <section class="__container">
      <div class="__section cart-org">
        <div class="__section__title">
          <h1>Карточка организации</h1>
        </div>
        <div class="cart-org__specifications">
          <SpecificationUnit v-bind="{ specifications: cartOrg }" />
        </div>
      </div>
    </section>
    <section class="__container">
      <div class="__section services">
        <div class="servisec__list">
          <p>Мы предлагаем широкий спектр услуг, включая:</p>
          <TypesWork v-bind="{ list: servicesList }" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Chapter from "@/components/Chapter.vue"
import Triad from "@/components/Triad.vue"
import SpecificationUnit from "@/components/unit/SpecificationUnit.vue"
import TypesWork from "@/components/TypesWork.vue"

export default {
  name: 'AboutView',
  components: { Chapter, Triad, SpecificationUnit, TypesWork },

  computed: {
    cartOrg() {
      const arr = [];
      arr.push({ title: "Именование", info: "Общество с ограниченной ответственностью «Все Решения»" }, { title: "ИНН", info: "2466296692" }, { title: "КПП", info: "246601001" }, { title: "ОГРН", info: "1232400023624" })
      return arr
    },
    servicesList() {
      const list = [];
      list.push('Ремонт и отделка помещений', 'Замена и установка остекления', 'Устройство и ремонт полов', 'Установка и ремонт сантехники', 'Электромонтажные работы', 'Ремонт и установка систем отопления', 'Гидроизоляция и защита от влаги', 'Облицовка плиткой и камнем', 'Покраска и обои', 'Монтаж и ремонт потолков', 'Установка и ремонт дверей и окон', 'Монтаж и ремонт фасадных систем');
      return list
    }
  }
}
</script>

<style scoped>
.cart-org__specifications {
  background-color: white;
  border-radius: 10px;
  padding: 25px;
}

.servisec__list {
  background-color: white;
  border-radius: 10px;
  padding: 25px;
}

.servisec__list p {
  padding: 0;
  margin: 0 0 20px;
  font-size: clamp(1rem, calc(8px + 2vw), 24px);
}
</style>