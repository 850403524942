<template>
    <div class="__container question">

        <div class="question__block">
            <div class="question__title">
                <h2>У вас появился вопрос?</h2>
            </div>
            <div class="question__info">
                <p>Позвоните <a href="tel:
+7 (993) 464-29-43">
+7 (993) 464-29-43</a> или напишите в <a
                        href="https://wa.me/79934642943">WhatsApp</a></p>
            </div>
        </div>
    </div>
</template>
 
<script>
export default {
    name: 'Question'
}
</script>
 
<style >
.question {
    display: flex;
    justify-content: center
}

.question__block {
    max-width: 855px;
    max-height: 140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 30px;
    background-color: white;
    border-radius: 10px;
    padding: 25px;
    margin: 80px 15px 0
}

.question__title h2 {
    margin: 0;
    padding: 0;
    font-size: clamp(1.4rem, calc(10px + 2vw), 36px);
    font-weight: 400;
    text-align: center;
}

.question__info a,
.question__info p {
    margin: 0;
    padding: 0;
    font-size: clamp(1rem, calc(8px + 1vw), 24px);
    font-weight: 400;
}

.question__info a {
    color: #68A4FF;
}

@media(max-width: 1024px) {
    .question__block {
        margin-top: 60px
    }
}
</style>