<template>
    <div class="finishing">
        <Chapter style="margin-top: 0;" v-bind="{ text: ['Стены'], src: 'finishingView/walls/Walls.webp' }" />
        <div class="__container">
            <div class="finishing__block">
                <div class="finishing__img">
                    <img src="/image/finishingView/walls/colorWall.webp" loading="lazy">
                </div>
                <div class="finishing__tilte">
                    <h2>
                        Покраска стен
                    </h2>
                </div>
                <div class="finishing__text">
                    <p>Окраска стен - один из самых популярных способов отделки. Стены могут быть покрашены в одну
                        цветовую
                        гамму или с использованием градиентов. Также, для окраски можно применять декоративные трафареты
                        или
                        даже создавать живописные композиции.</p>
                    <p>Выбор краски играет важную роль, так как она может отличаться по степени влагостойкости,
                        плотности,
                        паропроницаемости и экологичности. Поэтому важно подобрать подходящий вид краски для каждого
                        конкретного помещения.</p>
                </div>
            </div>
            <div class="finishing__block">
                <div class="finishing__img">
                    <img src="/image/finishingView/walls/wallpapering.webp" loading="lazy">
                </div>
                <div class="finishing__tilte">
                    <h2>
                        Поклейка обоев
                    </h2>
                </div>
                <div class="finishing__text">
                    <p>Обои - это один из наиболее востребованных способов отделки стен. Благодаря широкому выбору
                        узоров,
                        текстур и материалов, а также их практичности и доступности, обои являются привлекательным
                        вариантом
                        для декорирования стен. Их можно комбинировать с окраской, панелями или использовать различные
                        узоры
                        для создания визуально привлекательных результатов.
                    </p>
                    <p>Существует множество типов обоев (бумажные, виниловые, флизелиновые и другие), каждый со своими
                        уникальными свойствами, преимуществами и недостатками. Поэтому важно подобрать подходящий тип
                        обоев
                        для каждого помещения, чтобы обеспечить долговечность и эстетическое удовлетворение.</p>
                </div>
            </div>
            <div class="finishing__block">
                <div class="finishing__img">
                    <img src="/image/finishingView/walls/tiling.webp" loading="lazy">
                </div>
                <div class="finishing__tilte">
                    <h2>
                        Отделка стен плиткой
                    </h2>
                </div>
                <div class="finishing__text">
                    <p>Плитка - это оптимальное решение для многих помещений, включая кухни и ванные комнаты, и даже для
                        жилых зон. В настоящее время плитку используют даже для декора старинных печей в квартирах
                        старого
                        фонда, что добавляет особый шарм помещению. Большой выбор материалов, форм и узоров дает
                        практически
                        безграничные возможности для реализации самых изысканных идей.
                    </p>
                    <p>Красиво и качественно уложенная плитка будет радовать глаза хозяев и гостей, сохраняя свою
                        привлекательность на протяжении долгого времени, переживая не один косметический ремонт.</p>
                </div>
            </div>
            <div class="finishing__block">
                <div class="finishing__img">
                    <img src="/image/finishingView/walls/panels.webp" loading="lazy">
                </div>
                <div class="finishing__tilte">
                    <h2>
                        Отделка стен панелями
                    </h2>
                </div>
                <div class="finishing__text">
                    <p>Декоративные панели становятся все более популярным способом добавить оригинальности вашему
                        интерьеру, представляя собой простое и бюджетное решение. Основные преимущества панелей включают
                        легкость монтажа, простоту ухода за ними и возможность экономии при отделке стен.
                    </p>
                    <p>На рынке представлен широкий выбор материалов для панелей, таких как:
                    <ul>
                        <li>декоративные пластиковые панели;</li>
                        <li>панели из прессованного дерева, с шпоновым или ламинированным покрытием;</li>
                        <li>натуральные панели из пробки;</li>
                        <li>панели на основе гипсокартона, гипсоволокна, ЦСП и других.</li>
                    </ul>
                    </p>
                </div>
            </div>
            <div class="finishing__block">
                <div class="finishing__img">
                    <img src="/image/finishingView/walls/tree.webp" loading="lazy">
                </div>
                <div class="finishing__tilte">
                    <h2>
                        Отделка стен деревом
                    </h2>
                </div>
                <div class="finishing__text">
                    <p>Натуральное дерево - идеальный материал для создания уюта внутри помещения, ведь оно обладает не
                        только теплотой, но и экологичностью.
                    </p>
                    <p>Для натуральной облицовки стен можно использовать деревянную вагонку различных размеров, с
                        удобными
                        монтажными пазами для установки. Эта облицовка открывает большие интерьерные возможности,
                        поскольку
                        древесину можно формовать и комбинировать разные виды и обработки (тонирование, лакирование,
                        обжигание, покраска и т.д.).
                    </p>

                </div>
            </div>
            <div class="finishing__block">
                <div class="finishing__img">
                    <img src="/image/finishingView/walls/stone.webp" loading="lazy">
                </div>
                <div class="finishing__tilte">
                    <h2>
                        Отделка стен камнем
                    </h2>
                </div>
                <div class="finishing__text">
                    <p>Декоративный камень добавит интерьеру неповторимого шарма. Обширный выбор как искусственного, так
                        и
                        натурального камня позволяет воплотить любую дизайнерскую задумку. Камень вне зависимости от
                        вида
                        обладает уникальным внешним видом, благородством и фактурой. Сегодня декоративный камень
                        доступен в
                        форме плиты, кирпича, плитки, а также шпона.
                    </p>
                    <p>На рынке отделочных материалов представлены натуральные камни, такие как мрамор, гранит, сланец,
                        а
                        также многие другие, такие как оникс. Натуральный камень предлагает разнообразные текстуры,
                        включая
                        матовые, глянцевые и естественные необработанные поверхности. Искусственный камень представлен
                        широким спектром материалов, включая бетон, акрил, кирпич и известковый камень.
                    </p>
                </div>
            </div>
        </div>
        <Chapter v-bind="{ text: ['Потолки'], src: 'finishingView/ceilings/Сeilings.webp' }" />
        <div class="__container">
            <div class="finishing__block">
                <div class="finishing__img">
                    <img src="/image/finishingView/ceilings/tiled-ceilings.webp" loading="lazy">
                </div>
                <div class="finishing__tilte">
                    <h2>
                        Плиточные потолки
                    </h2>
                </div>
                <div class="finishing__text">
                    <p>Такие потолки чаще всего называют "Армстронг". Они состоят из направляющих профилей, каркаса из
                        "Т"-образных металлических реек и плит из спрессованных минеральных волокон.
                    </p>
                    <p>Обычно такие подвесные потолки используются в коммерческих помещениях, таких как офисы, магазины
                        или
                        кафе. Потолок типа "Армстронг" представляет собой доступное по цене, быстрое и функциональное
                        решение для выравнивания потолка. Кроме того, такие потолки обеспечивают легкий доступ к
                        межпотолочному пространству.</p>
                </div>
            </div>
            <div class="finishing__block">
                <div class="finishing__img">
                    <img src="/image/finishingView/ceilings/stretch-ceiling.webp" loading="lazy">
                </div>
                <div class="finishing__tilte">
                    <h2>
                        Натяжной потолок
                    </h2>
                </div>
                <div class="finishing__text">
                    <p>Самым современным, популярным и быстрым решением для решения этого вопроса являются натяжные
                        потолки.
                        Они обладают превосходными эксплуатационными характеристиками: не меняют цвет, не впитывают
                        запахи,
                        не притягивают пыль, легко моются, и в большинстве случаев обеспечивают хорошую вентиляцию.

                    </p>
                    <p>Недостатком натяжных потолков является их повышенная чувствительность к повреждениям, например,
                        от
                        острых предметов.</p>
                </div>
            </div>
            <div class="finishing__block">
                <div class="finishing__img">
                    <img src="/image/finishingView/ceilings/drywall.webp" loading="lazy">
                </div>
                <div class="finishing__tilte">
                    <h2>
                        Потолки из ГКЛ
                    </h2>
                </div>
                <div class="finishing__text">
                    <p>Встроенные потолки из гипсокартона - это классика. Этот материал настолько универсален, что
                        позволяет
                        проявить смелость и творчество в дизайне. Главным недостатком является относительно длительная
                        установка, но результат того стоит.
                    </p>
                </div>
            </div>
            <div class="finishing__block">
                <div class="finishing__img">
                    <img src="/image/finishingView/ceilings/slatted-ceilings.webp" loading="lazy">
                </div>
                <div class="finishing__tilte">
                    <h2>
                        Реечные потолки
                    </h2>
                </div>
                <div class="finishing__text">
                    <p>Реечные потолки формируются на основе несущего профиля с пазами, в которые вставляются подвесы,
                        уголки и панели, представляющие собой длинные тонкие металлические траверсы. Главным недостатком
                        таких конструкций является их уязвимость к деформации при монтаже и демонтаже. Однако, в целом,
                        реечные потолочные системы представляют собой достойный вариант отделки.
                    </p>
                </div>
            </div>
        </div>
        <Chapter v-bind="{ text: ['Полы'], src: 'finishingView/floors/Floors.webp' }" />
        <div class="__container">
            <div class="finishing__block">
                <div class="finishing__img">
                    <img src="/image/finishingView/floors/laminate.webp" loading="lazy">
                </div>
                <div class="finishing__tilte">
                    <h2>
                        Укладка ламината
                    </h2>
                </div>
                <div class="finishing__text">
                    <p>Ламинатный пол представляет собой популярное покрытие для пола, которое имеет как свои
                        преимущества,
                        так и недостатки.
                    </p>
                    <p>Среди достоинств ламината можно выделить его привлекательный внешний вид, доступность
                        разнообразных
                        дизайнерских решений, прочность и долговечность, а также удобство ухода. Ламинат устойчив к
                        царапинам, практически не выцветает, что делает его отличным выбором для помещений с высокой
                        проходимостью. Также, несмотря на то что его укладка является достаточно сложной, затраты и
                        время на
                        монтаж ламината сравнительно невысоки.
                    </p>
                </div>
            </div>
            <div class="finishing__block">
                <div class="finishing__img">
                    <img src="/image/finishingView/floors/cement.webp" loading="lazy">
                </div>
                <div class="finishing__tilte">
                    <h2>
                        Цементная стяжка
                    </h2>
                </div>
                <div class="finishing__text">
                    <p>Для обеспечения долговечности пола всегда важно иметь тщательно выполненное основание, и одним из
                        наиболее распространенных видов основания является цементная стяжка. Цементная стяжка
                        универсальна и
                        прочна, способна выравнивать перепады высот, уклоны, а также исправлять неровности и дефекты
                        плит
                        перекрытий. Она выдерживает значительные весовые, вибрационные и ударные нагрузки, не выступает
                        влагу и пар. Благодаря этим свойствам, данная стяжка применима как для промышленных, так и для
                        жилых
                        помещений.
                    </p>
                </div>
            </div>
            <div class="finishing__block">
                <div class="finishing__img">
                    <img src="/image/finishingView/floors/warm-floor.webp" loading="lazy">
                </div>
                <div class="finishing__tilte">
                    <h2>
                        Тёплые полы
                    </h2>
                </div>
                <div class="finishing__text">
                    <p>Укладка тёплых полов – это процесс создания комфортной атмосферы в помещении, путем обеспечения
                        равномерного и приятного тепла на полу. Такая система отопления имеет множество преимуществ и
                        доступна для установки в жилых, коммерческих и общественных зданиях.
                    </p>
                    <p>Одними из самых популярных и распространенных видов укладки теплых полов являются: электрическая
                        система, водяная система и укладка инфракрасных теплых полов.
                    </p>
                </div>
            </div>
            <div class="finishing__block">
                <div class="finishing__img">
                    <img src="/image/finishingView/floors/linoleum.webp" loading="lazy">
                </div>
                <div class="finishing__tilte">
                    <h2>
                        Укладка линолиума
                    </h2>
                </div>
                <div class="finishing__text">
                    <p>Линолеум – это одно из самых популярных и доступных напольных покрытий, которое широко
                        используется в
                        домах, офисах и других помещениях. Он представляет собой гибкое полотно, изготовленное из
                        полимерных
                        материалов, и обладает рядом преимуществ, делающих его идеальным выбором для многих областей
                        применения.
                    </p>
                    <p>Одним из основных преимуществ линолеума является его доступность. Он обычно дешевле большинства
                        других видов напольных покрытий, таких как ламинат или паркет, что делает его привлекательным
                        вариантом для тех, кто хочет сэкономить на ремонте. Линолеум также прост в укладке, и его можно
                        быстро и легко постелить на пол самостоятельно, не прибегая к помощи профессионалов.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Chapter from "@/components/Chapter.vue"
import Description from "@/components/Description.vue"


export default {
    name: 'FinishingWorkView',
    components: { Chapter, Description },
}
</script>

<style scoped>
.finishing__block {
    background-color: white;
    padding: 20px;
    margin: 0 15px 20px;
    border-radius: 10px;
    display: grid;
    grid-template-columns: 450px 1fr;
    grid-template-rows: 45px 1fr;
    column-gap: 30px
}

.finishing__block:last-child {
    margin-bottom: 0;
}

.finishing__img {
    grid-row-start: 1;
    grid-row-end: 3;
}

.finishing__img img {
    border-radius: 10px;
}

.finishing__tilte {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 2;
    grid-column-end: 3;
}

.finishing__tilte h2 {
    margin: 0;
    padding: 0;
    color: #F2693D;
    font-weight: 400;
    font-size: clamp(1.2rem, calc(8px + 3vw), 32px)
}

.finishing__text {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 2;
    grid-column-end: 3;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.finishing__text p,
.finishing__text li {
    font-weight: 300;
    font-size: clamp(0.9rem, calc(8px + 2vw), 20px)
}

.finishing__text ul {
    margin: -15px 0 0 0;
    padding-left: 24px;
}

@media(max-width: 1023px) {
    .finishing__img img {
        width: 340px;
    }

    .finishing__block {
        grid-template-columns: 340px 1fr;
        grid-template-rows: 40px 1fr;
        column-gap: 25px
    }
}

@media(max-width: 768px) {
    .finishing__block {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto 1fr;
        row-gap: 15px
    }

    .finishing__img {
        grid-row-start: 2;
        grid-row-end: 3;
    }

    .finishing__img img {
        width: 450px;
    }

    .finishing__tilte {
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 1;
        grid-column-end: 1;
    }

    .finishing__text {
        grid-row-start: 3;
        grid-row-end: 4;
        grid-column-start: 1;
        grid-column-end: 1;
    }
}

@media(max-width: 530px) {
    .finishing__img img {
        width: 80vw;
    }
}

@media(max-width: 375px) {
    .finishing__img img {
        border-radius: 5px;
    }

    .finishing__block {
        padding: 15px 20px;
    }
}
</style>