<template>
    <div class="portfolio">
        <Chapter style="margin-top: 0;" v-bind="{ text: ['Портфолио'], src: 'portfolioView/Portfolio.webp' }" />
        <div class="__container">
            <Triad />
            <div class="__section portfolio__block">
                <div class="slide">
                    <swiper style="border-radius: 10px;" :modules="modules" :slides-per-view="1" :space-between="50"
                        navigation :pagination="{ clickable: true }" :scrollbar="{ draggable: true }" @swiper="onSwiper"
                        @slideChange="onSlideChange">
                        <swiper-slide><img src="image/portfolioView/portfolio-1/image1.webp"></swiper-slide>
                        <swiper-slide><img src="image/portfolioView/portfolio-1/image2.webp"></swiper-slide>
                        <swiper-slide><img src="image/portfolioView/portfolio-1/image3.webp"></swiper-slide>
                        <swiper-slide><img src="image/portfolioView/portfolio-1/image4.webp"></swiper-slide>
                        <swiper-slide><img src="image/portfolioView/portfolio-1/image5.webp"></swiper-slide>
                        <swiper-slide><img src="image/portfolioView/portfolio-1/image6.webp"></swiper-slide>
                        <swiper-slide><img src="image/portfolioView/portfolio-1/image7.webp"></swiper-slide>
                        <swiper-slide><img src="image/portfolioView/portfolio-1/image8.webp"></swiper-slide>
                    </swiper>
                </div>
                <div class="portfolio__specification">
                    <SpecificationUnit v-bind="{ specifications: d1 }" />
                </div>
            </div>
            <div class="__section portfolio__block">
                <div class="slide">
                    <swiper style="border-radius: 10px;" :modules="modules" :slides-per-view="1" :space-between="50"
                        navigation :pagination="{ clickable: true }" :scrollbar="{ draggable: true }" @swiper="onSwiper"
                        @slideChange="onSlideChange">
                        <swiper-slide><img src="image/portfolioView/portfolio-2/image1.webp"></swiper-slide>
                        <swiper-slide><img src="image/portfolioView/portfolio-2/image2.webp"></swiper-slide>
                        <swiper-slide><img src="image/portfolioView/portfolio-2/image3.webp"></swiper-slide>
                        <swiper-slide><img src="image/portfolioView/portfolio-2/image4.webp"></swiper-slide>
                        <swiper-slide><img src="image/portfolioView/portfolio-2/image5.webp"></swiper-slide>
                        <swiper-slide><img src="image/portfolioView/portfolio-2/image6.webp"></swiper-slide>
                        <swiper-slide><img src="image/portfolioView/portfolio-2/image7.webp"></swiper-slide>
                        <swiper-slide><img src="image/portfolioView/portfolio-2/image8.webp"></swiper-slide>
                    </swiper>
                </div>
                <div class="portfolio__specification">
                    <SpecificationUnit v-bind="{ specifications: d2 }" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Chapter from "@/components/Chapter.vue";
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Triad from "@/components/Triad.vue";
import SpecificationUnit from "@/components/unit/SpecificationUnit.vue";

export default {
    name: 'PortfolioView',
    components: {
        Chapter,
        Swiper,
        SwiperSlide,
        Triad,
        SpecificationUnit
    },
    setup() {
        const onSwiper = (swiper) => {
            console.log(swiper);
        };
        const onSlideChange = () => {
            console.log('slide change');
        };
        return {
            onSwiper,
            onSlideChange,
            modules: [Navigation, Pagination, Scrollbar, A11y],
        };
    },
    computed: {
        d1() {
            const inf = [];
            inf.push({ title: "Тип ремонта", info: "Дизайнерский" }, { title: "Площадь", info: "62 кв. м" })
            return inf
        },
        d2() {
            const inf = [];
            inf.push({ title: "Тип ремонта", info: "Дизайнерский" }, { title: "Площадь", info: "65 кв. м" })
            return inf
        }
    }
}
</script>

<style scoped>
.slide {
    max-width: 789px;
    max-height: 384px;
}

.slide img {
    width: 100%;
}

.portfolio__block {
    background-color: white;
    border-radius: 10px;
    padding: 25px;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr;
    column-gap: 50px;
}

@media(max-width: 1439px) {
    .slide {
        max-width: 560px;
    }
}

@media(max-width: 1114px) {
    .slide {
        max-width: 460px;
    }
}

@media(max-width: 1000px) {
    .slide {
        max-width: 370px;
    }

    .portfolio__block {
        column-gap: 20px;
    }
}

@media(max-width: 880px) {
    .portfolio__block {
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        row-gap: 25px;
    }

    .slide {
        width: 80%;
        max-width: 650px;
        justify-self: center;
    }
}

@media(max-width: 768px) {
    .slide {
        width: 80%;
        max-width: 540px;
    }
}

@media(max-width: 628px) {
    .slide {
        max-width: 420px;
    }
}

@media(max-width: 628px) {
    .slide {
        width: 100%;
        max-width: 320px;
    }
}

@media(max-width: 424px) {

    .portfolio__block {
        padding: 25px 15px;
    }

    .slide {
        max-width: 310px;
    }
}

@media(max-width: 374px) {
    .slide {
        max-width: 280px;
    }
}

@media(max-width: 340px) {
    .slide {
        max-width: 260px;
    }
}
</style>