<template>
    <div class="chapter">
        <div class="chapter__image"><img :src="'/image/' + src" :alt="text"></div>
        <div class="chapter__title __container">
            <h1 v-for="title in text">{{ title }}</h1>
        </div>
    </div>
</template>
 
<script>
export default {
    name: 'Chapter',
    props: {
        text: {
            type: Array
        },
        src: {
            type: String
        }
    }
}
</script>
 
<style >
.chapter {
    width: 100%;
    height: 100%;
    min-width: 320x;
    min-height: 440px;
    margin: 80px 0 40px;
}

.chapter__image {
    position: relative;
}

.chapter__image img {
    position: absolute;
    width: 100%;
    height: 100%;
    min-width: 360px;
    min-height: 440px;
    object-fit: cover;
    object-position: left center;
}

.chapter__title {
    min-height: 440px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    font-weight: 700;
    color: white;
}

.chapter__title h1 {
    margin: 0;
    left: 0;
    top: 100px;
    font-size: clamp(2rem, 12vw, 96px);
    padding-left: 15px;
    z-index: 2;
}

@media (max-width: 1440px) {
    .chapter {
        min-height: 380px;
        margin: 60px 0 20px;
    }

    .chapter__image img {
        min-height: 380px;
    }

    .chapter__title {
        min-height: 380px;
    }
}

@media (max-width: 720px) {
    .chapter {
        min-height: 240px;
    }

    .chapter__image img {
        min-height: 240px;
    }

    .chapter__title {
        min-height: 240px;
    }
}

@media (max-width: 375px) {
    .chapter {
        min-height: 150px;
        min-width: 320px;
    }

    .chapter__image img {
        min-height: 150px;
        min-width: 320px;
    }

    .chapter__title {
        min-height: 150px;
        min-width: 320px;
    }
}
</style>