<template>
    <div class="triad-unit">
        <div class="triad-unit__image">
            <img :src="'/image/' + src" alt="{{ title }}">
        </div>
        <div class="triad-unit__description">
            <div class="triad-unit__title">
                <h3>{{ title }}</h3>
            </div>
            <div class="triad-unit__info">
                <p>{{ text }}</p>
            </div>
        </div>
    </div>
</template>
 
<script>
export default {
    name: 'TriadUnit',
    props: {
        src: { type: String },
        title: { type: String },
        text: { type: String }
    }
}
</script>
 
<style >
.triad-unit {
    max-width: 415px;
    height: 83px;
    width: 100%;
    border-radius: 10px;
    background-color: #FFF;
    display: flex;
    align-items: center;
    padding: 20px 12px;
    margin: 0 15px;
    flex: 1 1 300px
}

.triad-unit h3,
p {
    margin: 0;
    padding: 0;
}

.triad-unit__image img {
    max-width: 54px;
    width: 100%;
    height: auto;
}

.triad-unit__description {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 15px;
    width: 100%
}

.triad-unit__title h3 {
    font-weight: 500;
    font-size: clamp(1.2rem, calc(8px + 1vw), 24px)
}

.triad-unit__info p {
    font-weight: 300;
    font-size: clamp(1rem, calc(4px + 1vw), 20px)
}

@media (max-width: 1410px) {
    .triad-unit {
        max-width: 370px
    }
}

@media (max-width: 1280px) {
    .triad-unit {
        max-width: 330px;
    }

}

@media (max-width: 1152px) {
    .triad-unit {
        max-width: none;
        margin: 10px;

    }

    .triad-unit__title h3 {
        font-size: 24px;
    }

    .triad-unit__title p {
        font-size: 18px;
    }
}

@media (max-width: 374px) {
    .triad-unit__title h3 {
        font-size: 18px;
    }

    .triad-unit__title p {
        font-size: 14px;
    }
}
</style>